import React from 'react'

function About() {
    return (
        <div className="container center">
            <div className="section">
                <img className="responsive-img" src="/icons/low_qualitiy_logo_invert.jpg" alt=""/>
                <div className="card z-depth-1">
                    <div className="card-content">
                        <p>Beyond Hope</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
